import {useState} from 'react'
import './App.css'
import {Modal, Navbar, Nav, Button, Container, Form, Image,Row,Col,Card} from 'react-bootstrap'
import {ZoomIn} from "react-bootstrap-icons"
import { InView } from 'react-intersection-observer'

const App=()=> {
    const [expanded,setExpanded] = useState(false)
    const [activeTab,setActiveTab] = useState ('')
    const [email,setEmail] = useState('')
    const [message,setMessage] = useState('')
    const [control,setControl] = useState('')
    const [showModal,setShowModal] = useState(false)
    const [section,setSection] = useState({})

  return(
<Container fluid="xl">
<Navbar  bg="light" expand="sm" expanded={expanded}  >
<Navbar.Brand ><Row><img height="40" width="40" src="./assets/logo.png"/><SommApp fontsize={"1.3em"}/></Row><Row><span style={{fontSize:".7em",fontStyle:"italic"}}>"Tasting Notes for Professionals"</span></Row></Navbar.Brand>
<Navbar.Toggle aria-controls="basic-navbar-nav" onClick={()=> setExpanded(expanded ? false : "expanded")}/>
<Navbar.Collapse id="basic-navbar-nav">
<Nav onSelect={(tab,e) => {console.log('selectNav',tab,e);}} className="mr-auto" activeKey={activeTab} >
<Nav.Link eventKey={"about"} href={"#about"} onClick={() => setExpanded(false)}>About</Nav.Link>
<Nav.Link eventKey={"features2"} href={"#features2"}  onClick={() => setExpanded(false)}>System Features</Nav.Link>
<Nav.Link eventKey={"features1"} href={"#features1"}  onClick={() => setExpanded(false)}>Note Features</Nav.Link>
<Nav.Link eventKey={"contact"} href={"#contact"} onClick={() => setExpanded(false)}>Contact</Nav.Link>
</Nav>
<Navbar.Text>
</Navbar.Text>
</Navbar.Collapse>



</Navbar>

       <div style={{paddingTop:"90px"}} className={"banner"}>
        <Image src="./assets/rowofglasses-darkened-art-long.jpg" fluid />
        <br/><br/>
              <h2 style={{color:"maroon"}}>Coming Soon! ... <br/>No App before its time...</h2>
      </div>
          <br/><br/>
    <InView threshold={.2} onChange={(inView)=>{inView ? setActiveTab('about') : dummy()}}>
        {({ inView, ref, entry }) => (
            <div ref={ref} className={`fade-in-section ${inView ? 'is-visible' : ''}`}>
        <div id={"about"} className={"section"}>
            <Line/>
                    <h3 style={{color:"maroon"}}>About</h3>
                    <p><SommApp/> is a web app for quick note taking on phones, tablets, notebooks and desktops. Notes are stored in the cloud and accessible from multiple devices. Notes can be searched, shared, compared and saved in PDF format.</p>

                        <div>

                            <Col xs={5} sm={3} className={"float-right pr-0"}>
                                <Image fluid
                                       src="./assets/oneguytasting.jpg"
                                       alt="somm tasting wine"
                                />
                            </Col>

                            <p>The current version of <SommApp/> has templates intended for those working with The Court of Master Sommeliers tasting grids.</p>
                        <p>There is a demo mode of the app available HERE (Not Linked Yet). Pricing for the app is $25 for one year and allows use on all your devices.</p>
                            <p><Col lg={6}><Image src="./assets/editscreen-gold.png" fluid/></Col></p>

                        </div>
        </div>
            </div>
            )}
    </InView>

    <InView threshold={.2} onChange={(inView)=>{inView ? setActiveTab('features2') : dummy()}}>
        {({ inView, ref, entry }) => (
            <div ref={ref} className={`fade-in-section ${inView ? 'is-visible' : ''}`}>
                <div id="features2" className={"section clearfix"}>
                    <Line/>
                    <Col xs={5} sm={3}  className={"float-right pr-0"}>
                        <Image fluid className={"section-image"}
                               src="./assets/womanwinenote.jpg"
                               alt="Generic placeholder"
                        />
                    </Col>
                    <h3 style={{color:"maroon"}} className={"text-center"}>System Features</h3>
                    <p><SommApp/> is cloud based and considered a full screen web app or PWA.  It runs on any recent device or computer.</p>
                    <p>Being cloud based also allows notes and settings to be shared between all of your devices and there is no need to back up or transfer notes.</p>
                    <p>As a web app it can also run with no internet connection for taking notes offline. Notes will be automatically uploaded to our servers when app connects again.</p>
                    <br/>
                    <Row xs={1} sm={2}>
                        {sectionInfo.systemFeatures.sections.map((section)=> {
                            return (
                                <Col onClick={()=>{setSection(section); setShowModal(true)}} >
                                    <SectionCard section={section}/>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            </div>
        )}
    </InView>


    <InView threshold={.2} onChange={(inView)=>{inView ? setActiveTab('features1') : dummy()}}>
        {({ inView, ref, entry }) => (
            <div ref={ref} className={`fade-in-section ${inView ? 'is-visible' : ''}`}>
            <div id="features1" className={"section clearfix"}>
                <Line/>
                <Col xs={5} sm={3} className={"float-left pl-0"}>
                    <Image fluid className={"section-image"}
                           src="./assets/manwomannotes.jpg"
                           alt="Generic placeholder"
                    />
                </Col>

                <h3 style={{color:"maroon"}} className={"text-center"}>Note Taking Features</h3>
                <p>Unlike many note taking apps used for wine notes, <SommApp/> was designed specifically for professional sommeliers, or those in training.</p>
                <p>Although it has extensive features to simplify taking notes for wines, it is also designed to handle notes for other beverages.</p>
                <p>It also has fields for using the notes in your business and sharing notes with other somms or staff.</p>
                <br/><br/>
                <Row xs={1} sm={2}>
                    {sectionInfo.noteFeatures.sections.map((section)=> {
                        return (
                            <Col onClick={()=>{setSection(section); setShowModal(true)}}>
                                <SectionCard section={section} />
                            </Col>

                        )
                    })}
                </Row>




            </div>
            </div>
            )}
    </InView>


    <InView threshold={.3} onChange={(inView)=>{inView ? setActiveTab('contact') : dummy()}}>
        {({ inView, ref, entry }) => (
            <div ref={ref} className={`fade-in-section ${inView ? 'is-visible' : ''}`}>
              <div id={"contact"} className={"section clearfix"}>
                  <Line/>
                  <Row>
                  <Col xs={4} sm={4} className={"pl-0"}>
                      <Image className={"section-image"} fluid
                             src="./assets/notehand.jpg"
                             alt="Generic placeholder"
                      />
                  </Col>

                  <Col xs={8} sm={8}>
                      <h3 style={{color:"maroon"}} className={"text-center"}>Contact Us</h3>
                    <Form >
                        <Form.Group controlId="formEmail">
                            <Form.Label>Your Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" onChange={(e)=>setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="formMessage">
                            <Form.Label>Your Message</Form.Label>
                            <Form.Control as={"textarea"} placeholder="Enter message" onChange={(e)=>setMessage(e.target.value)}/>
                        </Form.Group>
                        <Form.Group controlId="formCheck">
                            <Form.Label>What is 'Cab' short for?</Form.Label>
                            <Form.Control type="text" placeholder="Spam prevention" onChange={(e)=>setControl(e.target.value)}/>
                        </Form.Group>

                        <div>
                        <Button onClick={()=>submit(email,message,control)}>Submit</Button>
                        </div>
                        </Form>
                  </Col>
                  </Row>
                  </div>
            </div>
            )}
            </InView>
              <div className={"clearfix"}>
        </div>

          <footer>
              <div>
                  <p>
                      <SommApp link={true} fontsize={"1.3em"}/> <i> "Tasting Notes for Professionals"</i><br/>
                      &#169;  2022 by Three Elements Design, LLC

                  </p>
              </div>
          </footer>
          <Modal show={showModal} id={"imagemodal"} onHide={()=>setShowModal(false)} size={"lg"}>
                <Modal.Body>
                    <h4>{section.title}</h4>
                    <div>{section.text}</div>
                    <Image src={"./assets/"+section.image} fluid />
                  </Modal.Body>
              <Modal.Footer>
                  <Button variant="outline-primary" onClick={()=>setShowModal(false)}>Close</Button>
              </Modal.Footer>
          </Modal>
</Container>

  )
}
export default App;

const submit = (email,message,control) => {
    console.log('submit',control)
    if (control.toLowerCase() === 'cabernet') {
        console.log(email,message,control)
        //var url = 'http://localhost:5001/sommapp1/us-central1/sendContactMail?dest=' + encodeURI(email) + '&name='+ encodeURI(name) +'&message=' + encodeURI(message);
        var url = 'https://us-central1-sommapp2.cloudfunctions.net/sendContactMail?dest=' + encodeURI(email) + '&name=' + '&message=' + encodeURI(message);
        //var url = 'https://sommapp.com/sendContactMail?dest=' + encodeURI(email) + '&name=' + '&message=' + encodeURI(message);
        fetch(url).then(response=>{return(response.text())}).then(text=>{alert(text)})

        /*
        var xmlhttp;
        xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            console.log('back')
            if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
                alert(xmlhttp.responseText);
            }
        };
        xmlhttp.open("GET", url, true);
        xmlhttp.send();*/
    } else {
        alert('Security answer is wrong... try a single word...')
    }
}

const SommApp=({link=false,fontsize="1em"})=>{
    if (link) {
        return(
        <span><a href="https://sommapp.com"><span style={{color:"maroon",fontSize:fontsize}}>Somm</span><span style={{color:"grey",fontSize:fontsize}}>App</span></a></span>
        )
    }
    else {
        return(
            <span><span style={{color:"maroon",fontSize:fontsize}}>Somm</span><span style={{color:"grey",fontSize:fontsize}}>App</span></span>

        )
    }
}
const sectionInfo = {
    systemFeatures:{
        title:"System Features",
        sections:[
            {image:"templates.png",
                title:"Template Customization",
                text:<p>Add frequently used options/descriptions to fields.<br/>
                Learning Fields" auto suggest from names added in the past. <br/>
                All customization works across each device.</p>
            },
            {image:"compare.jpg",
                title:"Compare Notes",
                text:<p>Up to four notes can be compared in a table, including those shared by others.</p>
            },
            {image:"alltypes.jpg",
                title:"In The Cloud",
                text: <p>Notes are stored in the "cloud" and are available from any device or computer. <br/>
                    New notes cab be made when offline for later automatic upload.<br/>
                    No need to backup, download or transfer notes between devices.<br/>
                </p>
            },
            {image:"tastingroom.JPG",
                title:"'Tasting' Rooms",
                text: <p>Create and invite other users to chat rooms where you can share and discuss notes.<br/>
                </p>
            },
        ]
    },
    noteFeatures:{
        title:"Note Taking Features",
        sections:[
            {image:"edit.jpg",
            title:"Fast Note Taking",
            text:<p>Minimize typing with option selects, sliding ranges and buttons.<br/>  Auto Learning fields suggest from previous inputs.
                <br/>Flexible, customizable selection options. Extra notes.  Section completion indicators.</p>
            },
            {image:"pdfwine.jpg",
                title:"PDF, Email, Spreadsheet",
                text:<p>Notes can be viewed in "grid" format and emailed as PDF or sharable link. </p>
            },
            {image:"notetypes.png",
                title:"Not Limited to Wine",
                text:<p>Wine templates based on the <a href="https://www.mastersommeliers.org/">The Court of Master Sommeliers</a> tasting grid.<br/>
                    Templates for beer, cocktails, and general notes are also available.<br/>
                    If you have interest in other tasting grids please contact us.</p>
            },
            {image:"extrafields.png",
                title:"Extra Fields",
                text: <p>Extra fields for price, cost, vendor, contact, rating and custom fields <br/>
                    Plus fields for an Image, File and automatic location</p>
            },
        ]
    }
}
const SectionCard = ({section}) => {
    return(
    <Card className="mb-4" >
        <Card.Title style={{color: "maroon"}} className={"text-center"}>{section.title}</Card.Title>
        <Card.Body>
            <div>
            <Image
                fluid
                className="d-block mx-auto"
                src={"./assets/" + section.image}
            />
            </div>
        </Card.Body>
        <Card.Text style={{color:"grey"}} className={"text-center"}><ZoomIn/>More</Card.Text>
    </Card>
    )
}
const Line = ()=>{
    return <div className={"line"}></div>
}
const dummy = () => { return undefined }
